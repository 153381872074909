import React, { useRef, useCallback, useState } from 'react';
import classes from 'classnames';
import { Transition } from 'react-transition-group';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { DefaultTFuncReturn } from 'i18next';

import { CreateCsvStudentsResponse } from '@/types/response';
import { StudentData, GroupStudentData } from '@/types/student-data';

import ResponseBody from './ResponseBody/ResponseBody';
import StudentRow from './StudentRow/StudentRow';
import AddStudentRow from './AddStudentRow/AddStudentRow';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';

import classNames from './StudentsTable.module.scss';

import closeIcon from '@/assets/svg/closeFill.svg';
import errorIcon from '@/assets/svg/error.svg';

interface StudentsTableProps {
  studentsData: StudentData[] | GroupStudentData[];
  setStudentsData?: React.Dispatch<React.SetStateAction<StudentData[]>>;
  studentsDataResponse?: CreateCsvStudentsResponse | null;
  allowActions?: boolean;
  allowNewRows?: boolean;
  title?: string | DefaultTFuncReturn;
  header?: any;
  disableSubmitButton?: boolean;
  setDisableSubmitButton?: React.Dispatch<React.SetStateAction<boolean>>;
  showNewStudentInput?: boolean;
  setShowNewStudentInput?: React.Dispatch<React.SetStateAction<boolean>>;
  isModal?: boolean;
  studentsInClass?: number;
}

const StudentsTable: React.FC<StudentsTableProps> = ({
  studentsData,
  setStudentsData,
  studentsDataResponse,
  allowActions,
  allowNewRows,
  title,
  header,
  disableSubmitButton,
  setDisableSubmitButton,
  showNewStudentInput,
  setShowNewStudentInput,
  isModal,
  studentsInClass = 0,
}) => {
  const { t } = useTranslation();
  const [showFullClassModal, setShowFullClassModal] = useState<boolean>(false);
  const tableContainerRef = useRef<HTMLTableSectionElement>(null);

  const maxStudentsNumberInClass = 50;
  const allowedStudentsNumber = maxStudentsNumberInClass - studentsInClass;

  const handleAddNewStudentRow = () => {
    if (studentsData.length >= allowedStudentsNumber) {
      setShowFullClassModal(true);
      return;
    }

    if (setShowNewStudentInput) {
      setShowNewStudentInput(true);
      setTimeout(() => {
        handleScroll();
      }, 0);
    }
  };

  const handleScroll = useCallback(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scrollTop = tableContainer.scrollHeight + 60;
    }
  }, []);

  const onCloseFullClassModal = () => {
    setShowFullClassModal(false);
  };

  const fullClassModal = (
    <Transition
      in={showFullClassModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div className={classes(classNames.modal, `fade-${state}`)}>
                <button className={classNames.closeBtn} onClick={onCloseFullClassModal}>
                  <SVG src={closeIcon} />
                </button>
                <SVG src={errorIcon} className={classNames.icon} />
                <h2>{t('editClassStudentFullClassTitle')}</h2>
                <span
                  className={classNames.description}
                  dangerouslySetInnerHTML={{
                    __html: t('editClassStudentFullClassDescription').toString(),
                  }}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const unregisteredStudents = studentsDataResponse?.data?.data?.unregisteredStudents?.length || 0;

  return (
    <>
      {fullClassModal}
      <div className={classNames.component}>
        {unregisteredStudents > 0 && (
          <span className={classNames.submitError}>
            <SVG src={closeIcon} className={classNames.checkmarkError} />
            {t('settings.add_students.errors.students_not_registered')}
          </span>
        )}
        <div
          className={isModal ? `${classNames.wrapper} ${classNames.isModal}` : classNames.wrapper}
        >
          {header}
          {title && <h3 className={classNames.tableTitle}>{title}</h3>}
          <table>
            <thead>
              <tr>
                <th>{t('firstname')}</th>
                <th>{t('lastname')}</th>
                <th>{t('email')}</th>
                {allowActions ? (
                  <th className={classNames.actionIcons}></th>
                ) : (
                  <th>{t('editClassStudentStatus')}</th>
                )}
              </tr>
            </thead>
            <tbody ref={tableContainerRef}>
              {studentsData.length > 0 &&
                studentsData.map((student, index) => (
                  <StudentRow
                    key={`student-row--${index}`}
                    student={student}
                    index={index}
                    allowActions={allowActions}
                    studentsData={studentsData}
                    setStudentsData={setStudentsData}
                    setDisableSubmitButton={setDisableSubmitButton}
                  />
                ))}
              {studentsDataResponse && <ResponseBody studentsDataResponse={studentsDataResponse} />}
              {showNewStudentInput && setShowNewStudentInput && setStudentsData && (
                <AddStudentRow
                  setStudentsData={setStudentsData}
                  setShowNewStudentInput={setShowNewStudentInput}
                />
              )}
            </tbody>
          </table>
        </div>
        {!studentsDataResponse && allowNewRows && (
          <div className={classNames.addStudent}>
            <button onClick={() => handleAddNewStudentRow()}>
              {t('settings.add_students.add_student')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

StudentsTable.defaultProps = {
  allowActions: false,
  allowNewRows: false,
};

export default StudentsTable;
