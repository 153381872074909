//TODO: Need to understand if it's needed
// import SkillsStatistics from './SkillsStatistics/SkillsStatistics';
import React from 'react';
import StudentsCards from './StudentsCards/StudentsCards';
import { Transition } from 'react-transition-group';
import AppBackdrop from '../AppBackdrop';
import AppModal from '../AppModal';
import SVG from 'react-inlinesvg';
import UserData from './UserData/UserData';
import { LocalLanguage, TeacherGroup, UserState } from '@/types/user';
import {
  StudentProgressBySkill,
  StudentsSkillsProgress,
  GroupStudentProgress,
  StudentMetadata,
  UnitProgress,
} from '@/types/progress';
import { SkillTypes } from '@/types/skill';
import classNames from './StudentsData.module.scss';
import classes from 'classnames';
import UseScreenSize from '@/hooks/UseScreenSize';
import close from '@/assets/svg/close.svg';
import { useTranslation } from 'react-i18next';

interface StudentsContentProps {
  students: GroupStudentProgress[];
  isStudentDataLoading?: boolean;
  statistics: StudentsSkillsProgress | null;
  selectedStudent: GroupStudentProgress | null;
  selectedStudentData: StudentMetadata | null;
  showStudentDataModal: boolean;
  searchValue: string;
  showUnitData?: boolean;
  showAssessments: boolean;
  selectedUnit?: UnitProgress | null;
  showEdit?: boolean;
  interfaceLanguage: LocalLanguage | undefined;
  currentClassroom: TeacherGroup;
  onShowUnitData?: (unitId: number) => void;
  onHideUnitData?: () => void;
  onShowAssessments: () => void;
  onHideAssessment: () => void;
  onCloseEdit?: () => void;
  onShowEdit?: () => void;
  onSaveEdit?: (firstName: string, lastName: string, language: string) => Promise<void>;
  setSearchValue: (value: string) => void;
  onShowDataModal: (studentId: string) => void;
  onHideDataModal: () => void;
  setIsClassEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudentsContent = ({
  statistics,
  students,
  searchValue,
  showStudentDataModal,
  interfaceLanguage,
  selectedStudent,
  selectedStudentData,
  showEdit,
  showAssessments,
  isStudentDataLoading,
  showUnitData,
  selectedUnit,
  currentClassroom,
  setSearchValue,
  onShowDataModal,
  onHideDataModal,
  onHideUnitData,
  onShowUnitData,
  onShowAssessments,
  onHideAssessment,
  onShowEdit,
  onCloseEdit,
  onSaveEdit,
  setIsClassEdit,
}: StudentsContentProps) => {
  const { isMobile } = UseScreenSize();
  const { t } = useTranslation();

  const onHideDataModalHandler = () => {
    if (showEdit && onCloseEdit) {
      onCloseEdit();
    } else {
      onHideDataModal();
    }
  };

  return (
    <>
      {
        //TODO: Need to understand if it's needed
        /* <SkillsStatistics statistics={statistics} /> */
      }
      <StudentsCards
        students={students}
        statistics={statistics}
        searchValue={searchValue}
        currentClassroom={currentClassroom}
        emitShowDataModal={onShowDataModal}
        setSearchValue={setSearchValue}
        setIsClassEdit={setIsClassEdit}
      />
      <Transition
        in={showStudentDataModal}
        timeout={400}
        mountOnEnter
        unmountOnExit
        children={state => (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
              emitClose={onHideDataModal}
            />
            <AppModal>
              {isMobile ? (
                <div className={classes(classNames.viewBlocker, `fade-${state}`)}>
                  <button className={classNames.close} onClick={onHideDataModal}>
                    <SVG src={close} />
                  </button>
                  <button className={classNames.text} onClick={onHideDataModal}>
                    {t('fullVersionAvilableOnlyOnDesktop')}
                  </button>
                </div>
              ) : (
                <div
                  className={classes(
                    classNames.modal,
                    `slide-horizontal-${interfaceLanguage?.direction}-${state}`
                  )}
                >
                  <button className={classNames.close} onClick={onHideDataModalHandler}>
                    <SVG src={close} />
                  </button>
                  {(selectedStudent || isStudentDataLoading) && (
                    <UserData
                      isLoadingProgress={isStudentDataLoading}
                      level={selectedStudent?.level}
                      firstName={
                        selectedStudentData
                          ? selectedStudentData.studentData.firstName
                          : selectedStudent?.firstName
                      }
                      lastName={
                        selectedStudentData
                          ? selectedStudentData.studentData.lastName
                          : selectedStudent?.lastName
                      }
                      grade={currentClassroom.grade}
                      className={currentClassroom.name}
                      interfaceLanguage={
                        selectedStudentData
                          ? selectedStudentData.studentData.interfaceLanguage
                          : selectedStudent?.languagePreferences?.interfaceLanguage || undefined
                      }
                      nativeLanguage={
                        selectedStudentData
                          ? selectedStudentData.studentData.nativeLanguage
                          : selectedStudent?.languagePreferences?.nativeLanguage || undefined
                      }
                      streak={
                        selectedStudentData ? selectedStudentData.progress?.userStreak : undefined
                      }
                      unitsProgress={
                        selectedStudentData ? selectedStudentData.progress.unitsProgress : undefined
                      }
                      studentProgress={{}}
                      showUnitData={showUnitData}
                      selectedUnit={selectedUnit}
                      selectedStudent={selectedStudent}
                      showAssessments={showAssessments}
                      organizationName={
                        selectedStudentData ? selectedStudentData.studentData.schoolName : ''
                      }
                      showEdit={showEdit}
                      currentLevelByskill={{}}
                      emitShowUnitData={onShowUnitData}
                      emitHideUnitData={onHideUnitData}
                      emitShowAssessments={onShowAssessments}
                      emitHideAssessment={onHideAssessment}
                      emitShowEdit={onShowEdit}
                      emitCloseEdit={onCloseEdit}
                      emitSaveEdit={onSaveEdit}
                    />
                  )}
                </div>
              )}
            </AppModal>
          </>
        )}
      />
    </>
  );
};

export default StudentsContent;
