import React from 'react';
import { GroupStudentProgress } from '@/types/progress';
import UseScreenSize from '@/hooks/UseScreenSize';

import StudentCardDesktop from './StudentCardDesktop';
import StudentCardMobile from './StudentCardMobile';

interface StudentCardProps {
  student: GroupStudentProgress;
  emitShowDataModal: (studentId: string) => void;
}

const StudentCard = ({ student, emitShowDataModal }: StudentCardProps) => {
  const { isMobile } = UseScreenSize();

  return isMobile ? (
    <StudentCardMobile
      level={student.level}
      firstname={student.firstName}
      lastname={student.lastName}
      userName={student.id}
      activitiesProgress={student.progress.activitiesProgress}
      score={student.progress.aVGScore}
      emitShowDataModal={emitShowDataModal}
    />
  ) : (
    <StudentCardDesktop
      level={student.level}
      firstname={student.firstName}
      lastname={student.lastName}
      userName={student.id}
      activitiesProgress={student.progress.activitiesProgress}
      score={student.progress.aVGScore}
      emitShowDataModal={emitShowDataModal}
    />
  );
};

export default React.memo(StudentCard);
