import classNames from './GameDetailsBlock.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import commonUtils from '@/utils/common';

import UseLocalLang from '@/hooks/UseLocalLang';
import UseScreenSize from '@/hooks/UseScreenSize';

import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { unitSelector } from '@/store/reducers/unit';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';
import { UnitAssets } from '@/types/units';
import { quizDescription } from '../helper';
import { Topic } from '@/types/topic';
import { Quiz } from '@/types/quiz';

type GameDetailsBlockProps = {
  className?: string;
  selectedTopic: Topic | null;
  selectedQuiz: Quiz | null;
  currentQuestion?: any;
  isPreparationScreen?: boolean;
  joinTitle?: boolean;
};

export const GameDetailsBlock = ({
  className,
  selectedQuiz,
  selectedTopic,
  currentQuestion,
  isPreparationScreen,
  joinTitle,
}: GameDetailsBlockProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const localLang = UseLocalLang();
  const { gameType } = useAppSelector(gameSelector);
  const { selectedUnit } = useAppSelector(unitSelector);
  const { unitsAssets } = UseUnitsAssets();
  const unitAssets: UnitAssets = unitsAssets[selectedUnit.order || 1];

  const quizTopic = selectedTopic
    ? commonUtils.getName(selectedTopic, localLang)
    : t('notAvailable');

  const quizName = selectedQuiz ? commonUtils.getName(selectedQuiz) : t('notAvailable');

  const quizUnit = selectedUnit?.name;

  const instructionText = currentQuestion
    ? commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)
    : '';

  const titleBlock = (
    <>
      {joinTitle && (
        <p className={classNames.joinTitle}>
          <span className={classNames.quizTitle} style={{ color: unitAssets.modalBorderColor }}>
            {quizUnit}
          </span>
          <span className={classNames.seperator}></span>
          <span className={classNames.quizName}>{quizTopic}</span>
        </p>
      )}
      {!joinTitle && (
        <>
          <p className={classNames.quizTitle} style={{ color: unitAssets.modalBorderColor }}>
            {quizUnit}
          </p>
          <p className={classNames.quizName}>{quizTopic}</p>
        </>
      )}
      <p className={classNames.quizSecondName}>{quizName}</p>
    </>
  );

  return (
    <div
      className={classes(classNames.gameDetailsBlock, className, {
        [classNames.gameDetailsBlockWithJoinTitle]: joinTitle,
      })}
    >
      <div className={classNames.details}>
        {titleBlock}
        <p className={classNames.quizDescription}>
          {isPreparationScreen
            ? `${t(quizDescription(gameType) + (isMobile ? 'Mobile' : ''))}`
            : instructionText}
        </p>
      </div>
    </div>
  );
};
