import classNames from './EditContainer.module.scss';

interface FieldProps {
  label?: string;
  children?: any;
}

const Field = ({ label, children }: FieldProps) => {
  return (
    <div className={classNames.field}>
      <div className={classNames.fieldLabel}>{label}</div>
      <div className={classNames.fieldContent}>{children}</div>
    </div>
  );
};

export default Field;
