import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from './YourClasses.module.scss';
import classes from 'classnames';
import { ClassConfig, TeacherGroup } from '@/types/user';
import SVG from 'react-inlinesvg';

import TheButton from '../TheButton/TheButton';
import SectionSeperator from '../SectionSeperator/SectionSeperator';
import Detail from '../UserInfo/Detail/Detail';

import rightArrow from '@/assets/svg/right-arrow-large.svg';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';
import { registerService } from '@/services/register';
import DealLimitReachedMessage from '@/components/DealLimitReachedMessage/DealLimitReachedMessage';
import LoaderModal from '@/components/LoaderModal/LoaderModal';

interface YourClassesProps {
  className?: string;
  classConfig: ClassConfig | null;
  filterdClassrooms: TeacherGroup[];
  selectedFilter?: string;
  onCreateClass: () => void;
  onSelectClass: (classroom: TeacherGroup) => void;
  setIsClassEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}

const YourClasses = ({
  className,
  classConfig,
  filterdClassrooms,
  selectedFilter,
  onCreateClass,
  onSelectClass,
  setIsClassEdit,
}: YourClassesProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const [showDealLimitReachedModal, setShowDealLimitReachedModal] = useState<boolean>(false);
  const [isOrganizationDetailsLoading, setIsOrganizationDetailsLoading] = useState<boolean>(false);
  const isComponentActive = useRef<boolean>(false);

  const isRtl = interfaceLanguage?.direction === 'rtl';
  const isHebrew = interfaceLanguage?.code === 'he';

  const gradesText = `${t('grade')}'s`;

  let gradeText = '';

  const isAll = selectedFilter === '*';

  const isUniversityGrade = selectedFilter === 'university';
  const isNoneGrade = selectedFilter === 'none';

  if (isAll) {
    gradeText = t('yourClasses');
  } else if (isUniversityGrade || isNoneGrade) {
    gradeText = t(selectedFilter);
  } else {
    gradeText =
      isNaN(+(selectedFilter ?? '')) || Number.isInteger(+(selectedFilter ?? ''))
        ? t(`grade_${commonUtils.fixGradeSymbol(selectedFilter ?? '')}`)
        : selectedFilter ?? '';
  }

  const titleText = isAll
    ? gradeText
    : `${gradeText}${!isUniversityGrade && !isNoneGrade ? ` ${gradesText}` : ''} ${t('classes')}`;

  const hebrewTitleText = isAll ? gradeText : `${t('classes')} ${gradeText}`;

  const filterdClassroomsElements = filterdClassrooms.map((classRoom, index) => {
    const isSelected = classRoom.id === classConfig?.id;
    const teacherGroups = user?.metadata?.organizationDetails?.teacher?.teacherGroups || [];
    const classObj = teacherGroups.find(i => i.id === classRoom.id);
    const studentsAmount = classObj?.totalStudents ?? 0;

    return (
      <div
        key={classRoom.id}
        className={classes(classNames.classroom, {
          [classNames.classroomSelected]: isSelected,
        })}
        onClick={() => {
          if (isSelected) {
            return;
          }

          onSelectClass(classRoom);

          if (setIsClassEdit) {
            setIsClassEdit(false);
          }
        }}
      >
        <div className={classNames.content}>
          <div className={classNames.texts}>
            <span className={classNames.name}>{classRoom.name}</span>
            <Detail className={classNames.detail} topic={t('students')} value={studentsAmount} />
          </div>
          <SVG
            className={classes({
              [classNames.arrowSvgRtl]: isRtl,
            })}
            src={rightArrow}
          />
        </div>
      </div>
    );
  });

  useEffect(() => {
    isComponentActive.current = true;

    return () => {
      isComponentActive.current = false;
    };
  }, []);

  const createClassHandler = async () => {
    if (isOrganizationDetailsLoading) {
      return;
    }
    setIsOrganizationDetailsLoading(true);

    let isAllowedToRegister: boolean = false;

    try {
      const organizationDetails = (await registerService.getOrganizationDetails(user))?.data.data;
      isAllowedToRegister = !!organizationDetails?.isAllowedToRegister;
    } catch (error) {}

    if (!isComponentActive.current) {
      return;
    }

    setIsOrganizationDetailsLoading(false);

    if (!isAllowedToRegister) {
      setShowDealLimitReachedModal(true);
      return;
    }

    onCreateClass();

    if (setIsClassEdit) {
      setIsClassEdit(true);
    }
  };

  const onCloseDealLimitReachedModal = useCallback(() => {
    setShowDealLimitReachedModal(false);
  }, []);

  return (
    <div className={classes(classNames.yourClasses, className)}>
      <DealLimitReachedMessage
        show={showDealLimitReachedModal}
        onClose={onCloseDealLimitReachedModal}
      />
      {isOrganizationDetailsLoading && <LoaderModal show={true} />}
      <div className={classNames.header}>
        <span className={classNames.title}>{isHebrew ? hebrewTitleText : titleText}</span>
        <TheButton
          className={classNames.addBtn}
          text={t('createNewClass')}
          plain
          shouldRecolor={false}
          emitOnClick={createClassHandler}
        />
      </div>
      {filterdClassrooms.length === 0 && <SectionSeperator className='mt-2' />}
      <div className={classNames.filterdClassrooms}>{filterdClassroomsElements}</div>
    </div>
  );
};

export default YourClasses;
