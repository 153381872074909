import classNames from './StudentsData.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';
import students from '@/assets/svg/three_students2.svg';
import { TeacherGroup } from '@/types/user';
import { useTranslation } from 'react-i18next';

import OnboardingInvitation from '../OnboardingInvitation/OnboardingInvitation';

interface NoStudentsProps {
  currentClassroom: TeacherGroup;
}

const NoStudents = ({ currentClassroom }: NoStudentsProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();

  return (
    <div
      className={classes(classNames.noStudents, {
        [classNames.isMobile]: isMobile,
      })}
    >
      <div className={classNames.noStudentsContent}>
        <SVG src={students} className={classNames.noStudentsImage} />
        <span className={classNames.class}>{`${t('class')} ${currentClassroom?.name}`}</span>
        <span className={classNames.notJoined}>{t('yourStudentsHaveNotJoined')}</span>
        <span className={classNames.progressText}>{t('asSoonAsTheyStart')}</span>
        {currentClassroom?.invitationLinkForStudents && (
          <OnboardingInvitation
            className={classNames.onboardingInvitation}
            link={currentClassroom?.invitationLinkForStudents}
            isBtn
          />
        )}
      </div>
    </div>
  );
};

export default NoStudents;
