import { useTranslation } from 'react-i18next';
import classNames from './DataContainer.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import commonUtils from '@/utils/common';
import UseThemeColors from '@/hooks/UseThemeColors';
import { UnitsProgress, StudentProgress } from '@/types/progress';
import { Streak, UserState } from '@/types/user';

import UserStreak from '@/components/UserStreak/UserStreak';
import ProgressCard from '@/components/ProgressCard/ProgressCard';

import school from '@/assets/svg/school.svg';
import diamond from '@/assets/svg/diamond.svg';
import world from '@/assets/svg/world.svg';
import AssessmentsButton from '../Assessments/AssessmentsButton/AssessmentsButton';

interface DataContainerProps {
  level: string;
  levelText: string;
  schoolName?: string | null;
  language?: string | null;
  progress: StudentProgress;
  streak?: Streak[] | null;
  unitsProgress?: UnitsProgress | null;
  selectedStudent: UserState | null | undefined;
  emitShowUnitData?: (unitId: number) => void;
  emitShowAssessments: () => void;
}

const DataContainer = ({
  schoolName,
  level,
  levelText,
  language,
  progress,
  streak,
  unitsProgress,
  selectedStudent,
  emitShowUnitData,
  emitShowAssessments,
}: DataContainerProps) => {
  const { t } = useTranslation();
  const { color } = UseThemeColors();

  const onViewUnitDetails = (unitId: number) => {
    emitShowUnitData?.(unitId);
  };

  const progressCards = unitsProgress
    ? Object.entries(unitsProgress).map(entry => {
        const [key, value] = entry;

        const { trailColor, pathColor } = commonUtils.getNewProgressColors(value.order) ?? {};

        const topics = {
          completed: value.completedTopics,
          total: value.totalTopics,
        };

        const activities = {
          completed: value.completedActivities,
          total: value.totalActivites,
        };

        return (
          <ProgressCard
            className={classNames.progressCard}
            key={key}
            unitId={value.unitId}
            title={value.unitName}
            percentage={value.progress ?? 0}
            topics={topics}
            activities={activities}
            trailColor={color(trailColor ?? '') ?? ''}
            pathColor={color(pathColor ?? '') ?? ''}
            onClick={onViewUnitDetails}
          />
        );
      })
    : null;

  return (
    <div className={classNames.dataContainer}>
      <div className={classNames.info}>
        {!!schoolName && (
          <div className={classNames.box}>
            <span>{t('schoolName')}</span>
            <div className={classNames.text}>
              <SVG src={school} />
              <span>{schoolName}</span>
            </div>
          </div>
        )}
        {Number(level) > 0 && (
          <div className={classNames.box}>
            <span>{t('level')}</span>
            <div className={classNames.text}>
              {level ? <SVG src={diamond} /> : null}
              <span>{`${level} | ${levelText}`}</span>
            </div>
          </div>
        )}
        {!!language && (
          <div className={classNames.box}>
            <span>{t('interfaceLanguage')}</span>
            <div className={classNames.text}>
              <SVG src={world} />
              <span>{language}</span>
            </div>
          </div>
        )}
      </div>
      {streak && (
        <div className={classNames.streakContainer}>
          <span className={classNames.streakTitle}>{t('selfPracticeStreak')}</span>
          <UserStreak className={classNames.streak} showTitle={false} streak={streak} />
        </div>
      )}
      {/**todo need remove false */}
      {false && <AssessmentsButton onClick={emitShowAssessments} />}
      <div
        className={classes(classNames.progress, {
          [classNames.noProgress]: !unitsProgress,
        })}
      >
        {unitsProgress ? (
          <>
            <h2>{t('progress')}</h2>
            <div className={classNames.progressCards}>{progressCards}</div>
          </>
        ) : (
          <span>{t('thisStudentHasNot')}</span>
        )}
      </div>
    </div>
  );
};

export default DataContainer;
