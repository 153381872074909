import config from '@/config';
import {
  LanguageSettingsResponse,
  LevelSettingsResponse,
  SetUserLanguageSettingsResponse,
  SkillSettingsResponse,
  UserAvatarResponse,
  UpdatedStudentSettingsResponse,
  UpdatedTeacherClassResponse,
  ContactInfoResponse,
  changeTeacherSkillLevelResponse,
  GetGroupResponse,
} from '@/types/response';
import { UserService } from '@/types/services';
import { UserState } from '@/types/user';
import axiosInstance from '@/utils/api';
import axiosInstanceNew from '@/utils/api-new';
import UserUtils from '@/utils/user';

export const userService: UserService = {
  fetchSkillSettings: async user => {
    const resp: SkillSettingsResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(user)}/Settings/Skills`
    );

    return resp;
  },
  fetchLevelSettings: async user => {
    const resp: LevelSettingsResponse | null = await axiosInstanceNew.get(
      `${config.apiVersion}/${UserUtils.fromToLanguage(user)}/settings/levels`
    );

    return resp;
  },
  fetchLanguageSettings: async user => {
    const resp: LanguageSettingsResponse | null = await axiosInstanceNew.get(
      `${config.apiVersion}/${UserUtils.fromToLanguage(user)}/settings/languages`
    );

    return resp;
  },
  setUserLanguageSettings: async (user, interfaceLanguage: string) => {
    const resp: SetUserLanguageSettingsResponse | null = await axiosInstanceNew.post(
      `${config.apiVersion}/user-settings/set-user-interface-language/${user?.metadata.id}`,
      {
        interfaceLanguageCode: interfaceLanguage,
      }
    );

    return resp;
  },
  setUserLogOut: async user => {
    await axiosInstance.post(
      `user-metadata/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/set-user-logout/${user?.metadata.id}`
    );
  },
  updateUserAvatar: async (user: UserState | null, avatarName: string, avatarColor: string) => {
    const resp: UpdatedStudentSettingsResponse | null = await axiosInstanceNew.post(
      `${config.apiVersion}/user-settings/update-user-settings/${user?.metadata.id}`,
      //firstName, lastName and interfaceLanguage are not needed here, but required by API
      {
        firstName: user?.metadata.firstName,
        lastName: user?.metadata.lastName,
        interfaceLanguage: {
          interfaceLanguageCode: user?.metadata.languagePreferences.interfaceLanguage,
        },
        avatar: {
          avatarName,
          avatarColor,
        },
      }
    );

    return resp;
  },
  updateStudentSettings: async (
    user: UserState | null,
    studentId: string,
    firstName: string,
    lastName: string,
    interfaceLanguage: string
  ) => {
    const resp: UpdatedStudentSettingsResponse | null = await axiosInstanceNew.post(
      `${config.apiVersion}/teacher-view/teacher-update-user-settings/${user?.metadata.id}/${studentId}`,
      {
        firstName,
        lastName,
        interfaceLanguage: {
          interfaceLanguageCode: interfaceLanguage,
        },
      }
    );

    return resp;
  },
  updateTeacherClass: async (user, groupDetails) => {
    const resp: UpdatedTeacherClassResponse | null = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/edit-group-details/${user?.metadata.id}`,
      groupDetails
    );

    return resp;
  },

  editGroup: async (user, groupId, groupDetails) => {
    const resp: UpdatedTeacherClassResponse | null = await axiosInstanceNew.post(
      `${config.apiVersion}/Teacher-view/update-group/${user?.metadata?.id}/${groupId}`,
      {
        groupName: groupDetails.groupName,
        groupGrade: groupDetails.grade,
      }
    );

    return resp;
  },

  getGroup: async (user, groupId) => {
    const resp: GetGroupResponse | null = await axiosInstance.get(
      `user-metadata/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/group/${user?.metadata.id}/${groupId}`
    );

    return resp;
  },

  changeTeacherSkillLevel: async (user, skill, level) => {
    const resp: changeTeacherSkillLevelResponse = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/my-games/teacher-level/${user?.metadata.id}`,
      { skill, level }
    );
    return resp;
  },

  changeStudentLevelBySkill: async (teacher, studentId, selectedLevelsBySkills) => {
    const resp: changeTeacherSkillLevelResponse = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        teacher
      )}/teacher/dashboard/my-students/student-profile/change-student-level-by-skill/${
        teacher?.metadata.id
      }/${studentId}`,
      selectedLevelsBySkills
    );
    return resp;
  },

  sendContactInfo: async (user, info) => {
    const resp: ContactInfoResponse | null = await axiosInstanceNew.post(
      `${config.apiVersion}/teacher-view/teacher-contact-us/${user?.metadata?.id}`,
      info
    );

    return resp;
  },

  fetchCanDoData: async (user: UserState | null) => {
    const resp: any | null = await axiosInstanceNew.get(
      `${config.apiVersion}/${UserUtils.fromToLanguage(user)}/settings/can-do-answers`
    );

    return resp;
  },
};
