import React, { useCallback, useEffect, useState } from 'react';
import classNames from './EditContainer.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import clearIcon from '@/assets/svg/close.svg';

interface TextFieldProps {
  value?: string;
  error?: string;
  onChange?: (value: string) => void;
}

const TextField = ({ value, error, onChange }: TextFieldProps) => {
  const [valueState, setValueState] = useState<string>('');

  useEffect(() => {
    setValueState(value ?? '');
  }, [value]);

  const onChangeHandler = useCallback(
    (event: any) => {
      const value = event?.target?.value ?? '';
      setValueState(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  const onClearHandler = useCallback(() => {
    setValueState('');
    if (onChange) {
      onChange('');
    }
  }, [onChange]);

  return (
    <div
      className={classes(classNames.textField, {
        [classNames.textFieldError]: !!error,
      })}
    >
      <input value={valueState} onChange={onChangeHandler} />
      {valueState && <SVG src={clearIcon} onClick={onClearHandler} />}
    </div>
  );
};

export default TextField;
