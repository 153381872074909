import React, { useState } from 'react';
import DataControls from './DataControls/DataControls';
import classNames from './StudentsData.module.scss';
import {
  StudentProgressBySkill,
  StudentsSkillsProgress,
  GroupStudentProgress,
} from '@/types/progress';
import { TeacherGroup, UserState } from '@/types/user';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import { useAppDispatch, useAppSelector } from '@/store';
import { SkillTypes } from '@/types/skill';

import AppLoaderCircle from '@/components/AppLoaderCircle';
import UseScreenSize from '@/hooks/UseScreenSize';
import SearchStudents from '../SearchStudents/SearchStudents';
import ClassroomsSelect from './ClassroomsSelect/ClassroomsSelect';
import StudentsContent from './StudentsContent';
import NoStudents from './NoStudents';
import OnboardingInvitation from '../OnboardingInvitation/OnboardingInvitation';
import { teacherDashboardActions } from '@/store/reducers/teacher-dashboard';
import { useNavigate } from 'react-router-dom';

interface StudentsDataProps {
  students: GroupStudentProgress[];
  classrooms: TeacherGroup[];
  isLoading: boolean;
  isStudentDataLoading?: boolean;
  statistics: StudentsSkillsProgress | null;
  selectedStudent: GroupStudentProgress | null;
  showStudentDataModal: boolean;
  currentClassroom: TeacherGroup;
  searchValue: string;
  showSkillData?: boolean;
  showAssessments: boolean;
  selectedSkill?: {
    value: SkillTypes;
    progress: StudentProgressBySkill;
  } | null;
  showEdit?: boolean;
  onShowStudentModal: (studentId: string) => void;
  onHideStudentModal: () => void;
  onSetCurrentClassroom: (classroom: TeacherGroup) => void;
  onShowSkillData?: (skill: SkillTypes) => void;
  onHideSkillData?: () => void;
  onShowAssessments: () => void;
  onHideAssessment: () => void;
  onCloseEdit?: () => void;
  onShowEdit?: (boolean?: boolean) => void;
  onSaveEdit?: (firstName: string, lastName: string, language: string) => Promise<void>;
  setSearchValue: (value: string) => void;
}

const StudentsData = ({
  students,
  classrooms,
  isLoading,
  isStudentDataLoading,
  statistics,
  selectedStudent,
  showStudentDataModal,
  currentClassroom,
  searchValue,
  showSkillData,
  showAssessments,
  selectedSkill,
  showEdit,
  onShowStudentModal,
  onHideStudentModal,
  onSetCurrentClassroom,
  onShowSkillData,
  onHideSkillData,
  onShowAssessments,
  onHideAssessment,
  onCloseEdit,
  onShowEdit,
  onSaveEdit,
  setSearchValue,
}: StudentsDataProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const filterByOptions = ['allLevels'];

  const { isMobile } = UseScreenSize();

  const [currentOption, setCurrentOption] = useState(filterByOptions[0]);

  const onFilterBy = (option: string) => {
    setCurrentOption(option);
  };

  const onShowDataModal = (studentId: string) => {
    document.body.style.overflow = 'hidden';
    onShowEdit?.(false);
    onShowStudentModal(studentId);
  };

  const onHideDataModal = () => {
    document.body.style.overflow = 'auto';

    onHideStudentModal();
  };

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const desktopDataControls = (
    <DataControls
      classrooms={classrooms}
      currentClassroom={currentClassroom}
      currentOption={currentOption}
      filterByOptions={filterByOptions}
      emitSetCurrentClassroom={onSetCurrentClassroom}
      emitFiltertBy={onFilterBy}
    />
  );

  const studentsContent = students.length ? (
    <StudentsContent
      interfaceLanguage={interfaceLanguage}
      onHideDataModal={onHideDataModal}
      onShowDataModal={onShowDataModal}
      searchValue={searchValue}
      selectedStudent={selectedStudent}
      selectedStudentData={null}
      setSearchValue={setSearchValue}
      showStudentDataModal={showStudentDataModal}
      showAssessments={showAssessments}
      statistics={statistics}
      students={students}
      currentClassroom={currentClassroom}
      isStudentDataLoading={isStudentDataLoading}
      onCloseEdit={onCloseEdit}
      onHideUnitData={onHideSkillData}
      onSaveEdit={onSaveEdit}
      onShowEdit={onShowEdit}
      //onShowUnitData={onShowSkillData}
      onShowAssessments={onShowAssessments}
      onHideAssessment={onHideAssessment}
      //selectedUnit={selectedSkill}
      showEdit={showEdit}
      showUnitData={showSkillData}
    />
  ) : (
    <NoStudents currentClassroom={currentClassroom} />
  );

  return (
    <div className={classNames.studentsData}>
      {isMobile ? (
        <div>
          <div className='container flex gap-4 justify-between items-center'>
            <SearchStudents searchValue={searchValue} onInputChange={onInputChange} />
            <ClassroomsSelect
              emitAddNewClass={() => {
                dispatch(teacherDashboardActions.onAdd());
                navigate('/dashboard/my-classes');
              }}
              classrooms={classrooms}
              currentClassroom={currentClassroom}
              emitSetCurrentClassroom={onSetCurrentClassroom}
            />
          </div>
          <div className={classNames.classNameAndLinkContainer}>
            <h2 className={classNames.className}>{currentClassroom?.name}</h2>
            <div>
              <OnboardingInvitation
                isBtn
                link={currentClassroom?.invitationLinkForStudents}
                className={classNames.copyBtn}
              />
            </div>
          </div>
        </div>
      ) : (
        desktopDataControls
      )}

      {isLoading ? (
        <div className={classNames.studentsLoading}>
          <AppLoaderCircle />
        </div>
      ) : (
        studentsContent
      )}
    </div>
  );
};

export default React.memo(StudentsData);
